<template>
  <section class="section-profile">
    <h2 class="section-profile__title">
      Недоступность кассы
    </h2>
    <InaccessibilityForm
      :selected-elements="selectedElements"
      :active-dropdown="activeDropdown"
      :selected-elements-id-array="selectedElementsId"
      @onInputSearchElement="onInputSearchElement"
      @selectElement="selectElement"
      @selectByFilter="selectByFilter"
      @resetSelectedElements="resetSelectedElements"
      @onCheckSelectedData="onCheckSelectedData"
      @toggleActiveDropdown="toggleActiveDropdown"
      @resetChangeDataFlag="resetChangeDataFlag"
      @togglePlugPreloader="togglePlugPreloader"
      @onChangeData="onChangeData"
    />
    <vPlugLoader
      v-show="loading"
      text="Выгружаем статистику"
    />
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { computed } from 'vue'
import vPlugLoader from '@/components/profile/plugForm/v-plug-loader.vue'
import InaccessibilityForm from '@/components/profile/inaccessibilityForm/v-Inaccessibility-form'
export default {
  name: 'VSectionInaccessibility',
  components: {
    InaccessibilityForm,
    vPlugLoader,
  },
  provide() {
    return {
      restaurants: computed(() => this.filteredRestaurants),
      selectedElementsCount: computed(() => this.selectedElementsCount),
      allElementsCount: computed(() => this.allElementsCount),
      cloudElementsCount: computed(() => this.cloudElementsCount),
      b2pElementsCount: computed(() => this.b2pElementsCount),
      checkSelectedData: computed(() => this.checkSelectedData),
      selectedElementsId: computed(() => this.selectedElementsId),
      activeFilter: computed(() => this.activeFilter),
      searchElement: computed(() => this.searchElement),
      changeData: computed(() => this.changeData),
    }
  },
  data() {
    return {
      searchElement: '',
      allRestaurants: [],
      selectedElements: [],
      selectedElementsId: [],
      checkSelectedData: false,
      activeDropdown: false,
      changeData: false,
      activeFilter: '',
      loading: false,
    }
  },
  computed: {
    ...mapGetters('user', ['GET_AUTHENTIFICATED']),
    ...mapGetters('restaurant', ['GET_RESTAURANTS']),
    filteredRestaurants() {
      const filteredData = this.allRestaurants.filter((e) => {
        return e.name.toLowerCase().includes(this.searchElement.toLowerCase())
      })
      return filteredData
    },
    selectedElementsCount() {
      const count = this.allRestaurants.reduce((acc, item) => {
        if (item.active) {
          acc++
        }
        return acc
      }, 0)
      return count
    },
    allElementsCount() {
      return this.GET_RESTAURANTS.length
    },
    cloudElementsCount() {
      return this.GET_RESTAURANTS.filter(
        (i) => i.acquiring?.code === 'CloudPayments'
      ).length
    },
    b2pElementsCount() {
      return this.GET_RESTAURANTS.filter(
        (i) => i.acquiring?.code === 'BEST2PAY_BARREL'
      ).length
    },
  },
  watch: {
    selectedElementsCount() {
      this.checkSelectedData = true
      this.changeData = true
    },
  },
  async mounted() {
    await this.FETCH_RESTAURANTS({
      token: this.GET_AUTHENTIFICATED,
      search: '',
    })
    ;(this.allRestaurants = this.GET_RESTAURANTS.map((i) => {
      return { ...i, active: i.stub ? true : false }
    })),
      this.allRestaurants.forEach((i) => {
        if (i.active) {
          this.selectedElementsId.push(i.id)
        }
      })

    setTimeout(() => {
      this.changeData = false
      this.TOGGLE_PRELOADER(false)
    }, 500)
  },
  methods: {
    ...mapActions('preloader', ['TOGGLE_PRELOADER']),
    ...mapActions('restaurant', ['FETCH_RESTAURANTS']),

    onInputSearchElement(val) {
      this.searchElement = val
    },
    selectElement(id) {
      this.activeFilter = ''
      this.allRestaurants.forEach((i) => {
        if (i.id === id) {
          i.active = !i.active
          return
        }
      })
    },
    togglePlugPreloader() {
      this.loading = !this.loading
    },
    selectByFilter(type) {
      this.activeFilter = type
      this.allRestaurants.forEach((i) => {
        i.active = false
      })
      if (type === 'all') {
        this.allRestaurants.forEach((i) => {
          i.active = true
        })
        return
      }

      if (type === 'cloud') {
        this.allRestaurants.forEach((i) => {
          if (i.acquiring?.code === 'CloudPayments') {
            i.active = !i.active
            return
          }
        })
      }

      if (type === 'b2p') {
        this.allRestaurants.forEach((i) => {
          if (i.acquiring?.code === 'BEST2PAY_BARREL') {
            i.active = !i.active
            return
          }
        })
      }
    },
    resetSelectedElements() {
      this.activeFilter = ''
      this.allRestaurants.forEach((i) => {
        i.active = false
      })
    },
    toggleActiveDropdown(val) {
      this.activeDropdown = val
    },
    onCheckSelectedData(value) {
      this.checkSelectedData = value
      const filteredArray = []
      this.allRestaurants.forEach((i) => {
        if (i.active) {
          filteredArray.push(i.id)
        }
      })
      this.selectedElementsId = filteredArray
      this.activeDropdown = false
    },
    resetChangeDataFlag() {
      this.changeData = false
      // this.selectedElementsId = []
      this.period = ''
    },
    onChangeData() {
      this.changeData = true
    },
  },
}
</script>
