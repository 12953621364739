<template>
  <div class="plugFormInfo">
    <div class="plugFormInfo__title">
      Статистика по недоступности кассы
    </div>
    <div class="plugFormInfo__description">
      Здесь вы можете выгрузить статистику по недоступности кассы
    </div>
  </div>
</template>

<script>
export default {
  name: 'InaccessibilityFormInfo',
}
</script>

<style lang="scss" scoped>
.plugFormInfo {
  position: absolute;
  left: calc(100% + 32px);
  top: 24px;
  width: 100%;
  max-width: 343px;
  @media (max-width: 1150px) {
    left: 0;
    top: calc(100% + 24px);
  }
  &__title {
    color: #010d35;
    font-weight: 600;
    font-size: 17px;
    margin-bottom: 12px;
  }
  &__description {
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: #4e5672;
  }
}
</style>
