<template>
  <div class="plugForm">
    <vPlugSelect
      :selected-elements="selectedElements"
      :active-dropdown="activeDropdown"
      placeholder="Ресторан"
      @onInputSearchElement="onInputSearchElement"
      @selectElement="selectElement"
      @selectByFilter="selectByFilter"
      @resetSelectedElements="resetSelectedElements"
      @onCheckSelectedData="onCheckSelectedData"
      @toggleActiveDropdown="toggleActiveDropdown"
    />
    <div
      class="vTimePeriod__period"
      :class="{ active: period }"
    >
      <div class="vTimePeriod__period-label">
        <div class="vTimePeriod__period-placeholder">
          Выбрать дату
        </div>

        <div class="vTimePeriod__period-value">
          {{ period ? convertUTC : '' }}
        </div>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3 9.5H21M7.5 3V6V3ZM16.5 3V6V3Z"
            stroke="#80869A"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M18 4.5H6C4.34315 4.5 3 5.84315 3 7.5V18C3 19.6569 4.34315 21 6 21H18C19.6569 21 21 19.6569 21 18V7.5C21 5.84315 19.6569 4.5 18 4.5Z"
            stroke="#80869A"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <date-picker
        v-model:value="period"
        :disabled-date="disabledAfterToday"
      />
    </div>
    <vButton
      :disabled="disabledBtn"
      @click="save"
    >
      Выгрузить таблицу
    </vButton>
    <InaccessibilityFormInfo />
    <vLightPreloader
      v-show="lightPreloader"
      :fixed="true"
      :transparent="true"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DatePicker from 'vue-datepicker-next'
import 'vue-datepicker-next/index.css'
import 'vue-datepicker-next/locale/ru'
import vLightPreloader from '@/components/v-light-preloader'
import vPlugSelect from '@/components/profile/plugForm/v-plug-select.vue'
import vButton from '@/components/v-button'
import InaccessibilityFormInfo from '@/components/profile/inaccessibilityForm/v-inaccessibility-form-info'
import axios from 'axios'

export default {
  name: 'InaccessibilityForm',
  components: {
    InaccessibilityFormInfo,
    vButton,
    vPlugSelect,
    vLightPreloader,
    DatePicker,
  },
  inject: ['selectedElementsId', 'changeData', 'restaurants'],
  props: {
    selectedElements: {
      type: Array,
      required: true,
      default: () => [],
    },
    activeDropdown: {
      type: Boolean,
      required: true,
      default: false,
    },
    selectedElementsIdArray: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  emits: [
    'onInputSearchElement',
    'selectElement',
    'selectByFilter',
    'resetSelectedElements',
    'onCheckSelectedData',
    'toggleActiveDropdown',
    'resetChangeDataFlag',
    'togglePlugPreloader',
    'onChangeData',
  ],
  data() {
    return {
      lightPreloader: false,
      blockTypesSelected: '',
      outsideClickData: null,
      period: null,
    }
  },
  computed: {
    ...mapGetters('user', ['GET_AUTHENTIFICATED']),
    convertUTC() {
      if (this.period) {
        return this.period.toLocaleString().split(',')[0]
      }
      return ''
    },
    disabledBtn() {
      return !this.period || !this.selectedElementsIdArray.length
    },
  },
  mounted() {
    const body = document.querySelector('body')
    body.classList.add('calendarPageOverYears')

    document.addEventListener('click', this.clickUotSide)
  },
  unmounted() {
    const body = document.querySelector('body')
    body.classList.remove('calendarPageOverYears')

    document.removeEventListener('click', this.clickUotSide)
  },
  methods: {
    clickUotSide(e) {
      this.outsideClickData = e.composedPath()
    },
    selectBlockType(value) {
      this.$emit('onChangeData')
      this.blockTypesSelected = value.name
    },
    async save() {
      this.$emit('togglePlugPreloader')
      const date = this.period.toISOString().split('T')[0]
      const shops = this.selectedElementsIdArray
        .map((elem) => `shop_ids[]=${elem}`)
        .join('&')

      try {
        const response = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/statistic/platform-activity?date=${date}&shop_selection=custom&${shops}`,
          {
            headers: {
              Authorization: 'Bearer ' + this.GET_AUTHENTIFICATED,
            },
            responseType: 'blob',
          }
        )

        console.log(response.data)

        const url = URL.createObjectURL(
          new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,',
          })
        )
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', this.convertUTC + '.xlsx')
        document.body.appendChild(link)
        link.click()
        link.remove()

        this.period = ''
      } catch (e) {
        console.log(e)
      } finally {
        this.$emit('resetChangeDataFlag')
        setTimeout(() => {
          this.$emit('togglePlugPreloader')
        }, 500)
      }
    },
    onInputSearchElement(val) {
      this.$emit('onInputSearchElement', val)
    },
    selectElement(id) {
      this.$emit('selectElement', id)
    },
    selectByFilter(type) {
      this.$emit('selectByFilter', type)
    },
    resetSelectedElements() {
      this.$emit('resetSelectedElements')
    },
    onCheckSelectedData(val) {
      this.$emit('onCheckSelectedData', val)
    },
    toggleActiveDropdown(val) {
      this.$emit('toggleActiveDropdown', val)
    },
    disabledAfterToday(date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      return today < date
    },
  },
}
</script>

<style lang="scss" scoped>
.plugForm {
  width: 100%;
  max-width: 636px;
  border: 1px solid #e6e7eb;
  border-radius: 11px;
  background: #ffffff;
  padding: 24px 24px 8px 24px;
  position: relative;
}

.vTimePeriod__period {
  display: flex;
  align-items: center;
  position: relative;
  background-color: #fff;
  border: 1px solid #e6e7eb;
  border-radius: 12px;
  width: 100%;
  margin-bottom: 20px;
  height: 52px;
  overflow: hidden;
  cursor: pointer;
  @media (max-width: 769px) {
    margin-top: 16px;
  }
  &-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 12px;
    font-size: 16px;
    cursor: pointer;
    color: #80869a;
  }

  &.active & {
    &-placeholder {
      top: 8px;
      font-size: 12px;
      transform: translateY(0);
    }

    &-value {
      padding-top: 18px;
      color: #010d35;
    }
  }

  &-placeholder {
    color: #80869a;
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
  }

  svg {
    stroke: #80869a;
  }
}
.mx-datepicker {
  cursor: pointer;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  opacity: 0;

  input {
    cursor: pointer;
    height: 100%;
  }
}
</style>

<style lang="scss">
.calendarPageOverYears {
  .mx-calendar-range .mx-calendar:last-child {
    display: none;
  }
  .mx-datepicker-main.mx-datepicker-popup {
    border: 1px solid #e6e7eb;
    border-radius: 12px;
    width: 375px;
    box-shadow: none;
  }
  .mx-btn.mx-btn-text.mx-btn-current-year,
  .mx-btn.mx-btn-text.mx-btn-icon-double-right,
  .mx-btn.mx-btn-text.mx-btn-icon-double-left {
    display: none;
  }
  .mx-calendar.mx-calendar-panel-date {
    width: 100%;
  }
  .mx-calendar-header-label {
    button {
      color: #010d35;
      font-weight: 600;
      font-family: 'Commissioner';
      font-size: 20px;
      &::first-letter {
        text-transform: uppercase;
      }
    }
  }
  .mx-table th {
    color: #010d35;
    font-family: 'Commissioner';
    font-size: 15px;
    font-weight: 400;
  }
  .mx-calendar-content .cell {
    color: #010d35;
    font-family: 'Commissioner';
    font-size: 16px;
    font-weight: 500;
    border-radius: 6px;
    width: 47px;
    height: 36px;
  }
  .mx-table-date .cell.not-current-month {
    color: #80869a;
    font-family: 'Commissioner';
    font-size: 16px;
    font-weight: 500;
  }
  .mx-calendar-content .cell:hover,
  .mx-calendar-content .cell.active,
  .mx-calendar-content .cell.in-range,
  .mx-calendar-content .cell.hover-in-range {
    background: #f8f9fe;
    color: #6764ff;
  }
  .mx-calendar-content .cell.in-range,
  .mx-calendar-content .cell.hover-in-range {
    border-radius: 0;
  }
  .mx-calendar-content .cell.active {
    border-radius: 12px 0 0 12px;
  }
  .cell.not-current-month.disabled:hover {
    background: none;
    color: #80869a;
  }
  .mx-calendar-content {
    height: auto;
  }
  .cell.in-range ~ .cell.active {
    border-radius: 0 12px 12px 0 !important;
  }
  .mx-calendar-content .cell.disabled {
    color: #ccc !important;
    background: none;
    opacity: 0.5;
  }
  .mx-calendar-content .cell.disabled:hover {
    color: #ccc;
  }
}
</style>
